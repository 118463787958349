<template>
  <v-app>
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner2 pt-6">
        <Overlays :is-absolute="true" :is-show="absoluteOverlayStatus"></Overlays>
        <v-card class="my-12 pt-10 pb-8 px-6">
          <div class="d-flex justify-center mb-9" style="font-size: 20px; font-weight: bold">
            <span>電話番号を変更する</span>
          </div>
          <template v-if="apierror.status == 'error'">
            <div v-for="msg of apierror.messages" :key="msg">
              <v-row class="ml-6 mb-3 ma-3">
                <span style="color: red">* {{ msg }} </span>
              </v-row>
            </div>
          </template>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="11">
              <v-row>
                <v-col cols="12" md="4">
                  <div>
                    <span> 現在の電話番号</span>
                    <span
                      class="ml-1 pr-1"
                      style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                    >
                      必須</span
                    >
                  </div>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="$v.Form.old_mobile.$model"
                    :error-messages="mobileOldErrors"
                    hide-details="auto"
                    outlined
                    readonly
                    dense
                    color="#ff6e40"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <div>
                    <span> 新しい電話番号</span>
                    <span
                      class="ml-1 pr-1"
                      style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                    >
                      必須</span
                    >
                  </div>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="$v.Form.new_mobile.$model"
                    :error-messages="mobileNewErrors"
                    hide-details="auto"
                    outlined
                    dense
                    color="#ff6e40"
                    :placeholder="info.mbr_mobile"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <div>
                    <span> 新しい電話番号(確認)</span>
                    <span
                      class="ml-1 pr-1"
                      style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                    >
                      必須</span
                    >
                  </div>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="$v.Form.confirm_mobile.$model"
                    :error-messages="confirmMobileErrors"
                    hide-details="auto"
                    outlined
                    dense
                    color="#ff6e40"
                    :placeholder="info.mbr_mobile"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-9">
                <v-spacer></v-spacer>
                <v-col cols="6" sm="3" class="d-flex justify-end">
                  <v-btn outlined width="100%" depressed @click="back">
                    <span>取消</span>
                  </v-btn>
                </v-col>
                <v-col cols="6" sm="3" class="d-flex justify-end">
                  <v-btn color="#ff6e40" depressed width="100%" @click="submit()">
                    <span style="color: #fff">変更</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-card>
      </div>
    </div>
  </v-app>
</template>

<script>
/* eslint-disable */
import { mapState, mapMutations, mapActions } from 'vuex'
import { required, sameAs } from 'vuelidate/lib/validators'
import { helpers } from '@vuelidate/validators'
import Overlays from '@/components/Overlays.vue'
/* eslint-disable */

const validMobile = helpers.regex(/^0[0-9]{9,10}$/)
export default {
  components: {
    Overlays,
  },
  data: () => ({
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    Form: {
      old_mobile: '',
      new_mobile: '',
      confirm_mobile: '',
    },
  }),
  validations: {
    Form: {
      old_mobile: {
        required,
        validMobile,
      },
      new_mobile: {
        required,
        validMobile,
      },
      confirm_mobile: {
        required,
        validMobile,
        sameAs: sameAs('new_mobile'),
      },
    },
  },
  computed: {
    ...mapState('app', ['absoluteOverlayStatus']),
    ...mapState('auth', ['info']),

    mobileOldErrors() {
      const errors = []
      if (!this.$v.Form.old_mobile.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.old_mobile.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.old_mobile.validMobile && errors.push('0から始まる10桁または11桁の番号を入力して下さい。')

      return errors
    },
    mobileNewErrors() {
      const errors = []
      if (!this.$v.Form.new_mobile.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.new_mobile.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.new_mobile.validMobile && errors.push('0から始まる10桁または11桁の番号を入力して下さい。')

      return errors
    },

    confirmMobileErrors() {
      const errors = []
      if (!this.$v.Form.confirm_mobile.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.confirm_mobile.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.confirm_mobile.validMobile && errors.push('0から始まる10桁または11桁の番号を入力して下さい。')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.confirm_mobile.sameAs && errors.push('上記「電話番号」と一致していません。')

      return errors
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapMutations('app', ['setAbsoluteOverlayStatus', 'setOverlayStatus']),
    ...mapActions('auth', ['ChangeMobile']),
    ...mapActions('auth', ['loadInfo']),

    loadData() {
      this.setAbsoluteOverlayStatus(true)
      this.loadInfo().then(() => {
        this.Form.old_mobile = this.info.mbr_mobile
        this.setAbsoluteOverlayStatus(false)
      })
    },

    submit() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        const editForm = { mbr_mobile: this.Form.new_mobile }
        this.ChangeMobile(editForm)
          .then(() => {
            this.back()
          })
          .catch(error => {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          })
      }
    },
    back() {
      this.$router.push('/account')
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
